import React from 'react'

const ProjectColumn = ({ children, className }) => {
  return (
    <div className={`lg:w-1/2 max-w-xl mx-auto flex flex-col justify-center items-center lg:items-start ${className}`}>
      {children}
    </div>
  )
}

export default ProjectColumn
