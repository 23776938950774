import React from 'react'
import { OutboundLink } from 'gatsby-plugin-google-analytics'

import Layout from '../components/Layout'
import SEO from '../components/seo'
import Hero from '../components/Hero'
import ProjectRow from '../components/ProjectRow'
import ProjectColumn from '../components/ProjectColumn'

import reactBricksLogo from '../images/project_reactbricks_logo.svg'
import reactBricksImg from '../images/project_reactbricks.gif'
import portfolioImg from '../images/project_portfolio.svg'

const ProjectsPage = () => {
  return (
    <Layout>
      <SEO
        description="F2.net develops React-based CMS and e-commerce solutions."
        keywords={[
          `F2.net`,
          `ReactBricks`,
          `react cms`,
          `react web applications`,
          `react ecommerce`,
        ]}
        title="Projects, Talks and Portfolio"
      />

      <Hero>
        <h1 className="text-3xl sm:text-5xl text-center font-black leading-tight mb-3">
          Projects
        </h1>
        <p className="text-xl text-center leading-relaxed text-gray-700">
          We are active in the React community, we develop React-based CMS and
          e-commerce solutions and sometimes we speak at conferences.
        </p>
      </Hero>

      <section className="max-w-4xl mx-auto py-6 md:py-20 px-6">
        <ProjectRow>
          <ProjectColumn className="mb-16 lg:mb-0">
            <OutboundLink
              className="w-40 mb-6"
              href="https://www.reactbricks.com/"
              rel="noopener noreferrer"
              target="_blank"
            >
              <img src={reactBricksLogo} alt="React Bricks" />
            </OutboundLink>
            <h1 className="text-2xl sm:text-3xl font-title font-semibold leading-tight mb-6">
              React Blocks-based CMS
            </h1>
            <p className="text-lg leading-relaxed text-gray-700 mb-8 text-center lg:text-left">
              Define your content bricks, create a blazing fast Gatsby or
              Next.js static site with Admin dashboard, host it wherever you
              want.
            </p>
            <p className="text-lg">
              <a
                className=""
                href="https://www.reactbricks.com/"
                rel="noopener noreferrer"
                target="_blank"
              >
                See Project Website »
              </a>
            </p>
          </ProjectColumn>
          <ProjectColumn>
            <div className="rounded-lg overflow-hidden shadow-lg border border-gray-200 mb-8">
              <img src={reactBricksImg} alt="Demo React Bricks" />
            </div>
          </ProjectColumn>
        </ProjectRow>
      </section>

      <div className="max-w-4xl mx-auto px-4">
        <hr />
      </div>

      <section className="max-w-4xl mx-auto py-6 md:py-20 px-6">
        <h1 className="block text-2xl sm:text-3xl mx-auto text-center lg:text-left font-title font-semibold leading-tight mb-6">
          Talks
        </h1>
        <ProjectRow>
          <ProjectColumn className="mb-16 lg:mb-0">
            <div className="embed-responsive aspect-ratio-16/9 mx-auto rounded-lg shadow-lg">
              <iframe
                title="react-js-day-2019"
                className="embed-responsive-item"
                src="https://www.youtube.com/embed/BEYg11nZev4"
              ></iframe>
            </div>
            <h3 className="block text-lg sm:text-xl font-extrabold mt-10 mb-3 mx-auto text-center">
              Matteo Frana @ React JS Day 2019
            </h3>
            <p className="text-lg leading-relaxed text-gray-700 mb-8 text-center">
              Reusing Stateful Logic in React: HOC vs Render Props vs Hooks
            </p>
          </ProjectColumn>
          <ProjectColumn>
            <div className="embed-responsive aspect-ratio-16/9 mx-auto rounded-lg shadow-lg">
              <iframe
                title="universal-js-day-2017"
                className="embed-responsive-item"
                src="https://www.youtube.com/embed/1BjgwtG92CI"
              ></iframe>
            </div>
            <h3 className="block text-lg sm:text-xl font-extrabold mt-10 mb-3 mx-auto text-center">
              Matteo Frana @ Universal JS Day 2017
            </h3>
            <p className="text-lg leading-relaxed text-gray-700 mb-8 text-center">
              React SSR in the Wild: yes, we can (if&nbsp;we&nbsp;should)
            </p>
          </ProjectColumn>
        </ProjectRow>
      </section>

      <div className="max-w-4xl mx-auto px-4">
        <hr />
      </div>

      <section className="max-w-4xl mx-auto py-6 md:py-20 px-6">
        <ProjectRow>
          <ProjectColumn className="mb-16 lg:mb-0">
            <h1 className="text-2xl sm:text-3xl font-title font-semibold leading-tight mb-6">
              Our portfolio{' '}
              <small className="text-gray-500">(coming soon)</small>
            </h1>
            <p className="text-lg leading-relaxed text-gray-700 mb-8 text-center lg:text-left">
              We have built web applications for Comune di Bergamo (Municipality
              of Bergamo), GLS Courier, OMCN S.p.A., Galdram S.p.A., CISL
              Lombardia (Trade Union), Court of Bergamo, Vodafone Italia,
              Cattolica Assicurazioni.
            </p>
          </ProjectColumn>
          <ProjectColumn>
            <img className="mx-auto mb-8" src={portfolioImg} alt="portfolio" />
          </ProjectColumn>
        </ProjectRow>
      </section>
    </Layout>
  )
}

export default ProjectsPage
