import React from 'react'

const ProjectRow = ({ children }) => {
  return (
    <div className="lg:flex lg:justify-between lg:space-x-20">
      {children}
    </div>
  )
}

export default ProjectRow
